import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'
import Icon from '../components/Icon/Icon'

import { formatTitle } from '../helpers/stringHelpers'

import { BsArrowRight } from 'react-icons/bs'

const workshopsTemplate = ( { data: { strapi: { workshop } }, location } ) => {

	return (
		<>
			<SEO title={ `${ workshop.categoryTitle } | Workshop ` } description={ workshop.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<Icon icon={ workshop.icon } cssClass="article__icon" />
					<h1 className="article__header" dangerouslySetInnerHTML={ formatTitle( workshop.pageTitle ) } />
					<h2 className="article__sub-header">Workshop</h2>

					<div className="article__body-content">
						{ workshop && workshop.content &&
							<DynamicZone components={ workshop.content } />
						}
					</div>

					<div className="article__link-container">
						<h3 className="article__link-heading">Looking for more of our workshops?</h3>
						<Link to="/workshops" className="article__link">
							View our workshops
							<BsArrowRight className="article__link-icon" />
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default workshopsTemplate

export const query = graphql`
	query workshopQuery($id: ID!) {
		strapi {
			workshop(id: $id) {
				id
				categoryTitle
				icon
				pageTitle
				seoDescription
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						displayBorder
						rotateImage
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`